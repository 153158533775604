<template>
  <div>
    <div style="height:50px">
    </div>
    <v-container fluid >
      <v-layout wrap row>
        <v-flex xs12 sm12 md12 class="pb-3">
          <v-card
              class="pa-2 vCard"
              outlined
          >
          <v-row no-gutters>
            <v-col cols="1">
            </v-col>
            <v-col cols="3" style="border-left : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb; border-bottom: 1px solid #cbcbcb">
              <div style="height: 10px"></div>
              <v-row no-gutters >
                <v-col cols="1">
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-col>
                      <span class="spanTitle">* 샘플군/대조군 선택</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="isSampleControlSelection">
                    <v-col cols="1"></v-col>
                    <v-col>
                      <v-btn value="S"
                             :class="[isSelectedButton ? 'buttonFocusIn' : 'buttonFocusOut']"
                             @click="samples"
                      >
                        샘플군
                      </v-btn>
                    </v-col>
                    <v-col >
                      <v-btn value="G"
                             :class="[isSelectedButton ? 'buttonFocusOut' : 'buttonFocusIn']"
                             @click="controlGroup"
                      >
                        대조군
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col>
                      <v-btn value="G1"
                             :class="[isSelectedButtonG1 ? 'buttonFocusIn' : 'buttonFocusOut']"
                             @click="compareThreeGroupButton"
                      >
                        그룹1
                      </v-btn>
                    </v-col>
                    <v-col >
                      <v-btn value="G2"
                             :class="[isSelectedButtonG2 ? 'buttonFocusIn' : 'buttonFocusOut']"
                             @click="compareThreeGroupButton"
                      >
                        그룹2
                      </v-btn>
                    </v-col>
                    <v-col >
                      <v-btn value="G3"
                             :class="[isSelectedButtonG3 ? 'buttonFocusIn' : 'buttonFocusOut']"
                             @click="compareThreeGroupButton"
                      >
                        그룹3
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
<!--
                <v-col cols="4" @click="samples">
                  <v-btn
                      color="blue-grey"
                      class="ma-2 white&#45;&#45;text"
                  >
                    샘플군
                  </v-btn>
                </v-col>
                <v-col cols="2">
                </v-col>
                <v-col cols="4">
                  <v-btn
                      color="blue-grey"
                      class="ma-2 white&#45;&#45;text"
                      @click="controlGroup"
                  >
                    대조군
                  </v-btn>
                </v-col>
-->
                <v-col cols="1">
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="1">
                </v-col>
                <v-col cols="10">
                    <v-autocomplete
                        style="margin-top: 0px;"
                        v-model="selectedGroup"
                        :items="items"
                        :menu-props="{ maxHeight: '400' }"
                        :loading = "loading"
                        label="* 분석대상 그룹선택"
                        @change="selectGroup"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="1">
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" style="
                   border-left : 1px solid #ffffff; border-top : 1px solid #cbcbcb; border-bottom: 1px solid #cbcbcb"
            >
            <div style="height: 25px;"> </div>
              <v-card
                  class="pa-2 vCard"
                  outlined
                  tile
              >
                <v-row >
                  <v-col> &nbsp; </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3" align-self="center">
                    연령:
                  </v-col>
                  <v-col cols="3">
                    <input class="inputAge" type="number" :value="startAgeValue"  @focusout="startAge">
                  </v-col>
                  <v-col cols="3" align-self="center" align="center">
                    <b>~</b>
                  </v-col>
                  <v-col cols="3">
                    <input class="inputAge" type="number" :value="endAgeValue"  @focusout="endAge">
                  </v-col>
                </v-row>
                <v-row no-gutters  >
                    <v-select
                        style="margin-top: 0px;"
                        v-model="selectedSymbol"
                        :items="itemsSymbol"
                        :menu-props="{ maxHeight: '200' }"
                        :loading = "loading"
                        return-object
                        label="* 분석대상 검색 조건 선택"
                        @change="selectCondition"
                    ></v-select>
                </v-row>

              </v-card>  <!-- 군별 선택 및 나이 -->
            </v-col>
            <v-col cols="3" style="border-bottom : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb;"> <!-- 분석 방식 선택 -->
              <v-row no-gutters>
                <v-col>
                  <div style="height: 5px;"> </div>
                  <v-card
                      class="pa-3 vCard"
                      outlined
                      tile
                  >
                    <v-select
                        v-model="analysisSelectStr"
                        :items="analysisSelectList"
                        label="분석 방법 선택"
                        height="16px"

                        @change="changeAnalysisSelect"
                    ></v-select>
<!--                    <v-select v-if="isDetailSelect"
                              :items="analysisDetailSelectList"
                              height="16px"
                              label="상세 선택"
                    ></v-select>-->
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="2">
                  <v-switch
                      color="primary"
                      value
                      input-value="true"
                      @change="changeResultValue"
                  ></v-switch>
                </v-col>
                <v-col  align-self="center"><span class="spanTitle">{{isLogValueStr}}</span></v-col>
              </v-row>

            </v-col>
            <v-col cols='2' align-self="stretch" align="center"  style="border-bottom : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb; border-right: 1px solid #cbcbcb"> <!-- 군집 분석  -->
              <v-row>
                <v-col>
                  &nbsp;
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <v-btn
                      depressed
                      color="blue-grey"
                      class="ma-2 white--text"
                      height="100px"
                      :loading="loading"
                      :disabled="loading"
                      @click="run"

                  >
                    군집분석 실행
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  &nbsp;
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1">
            </v-col>
          </v-row>
          <v-row no-gutters >
            <v-col cols="1">
            </v-col>
            <v-col cols="1" style="border-bottom: 3px double #cbcbcb">
            </v-col>
            <v-col :class="[isSampleControlSelection ? 'searchConditionAreaType1' : 'searchConditionAreaType2']">
              (현재 선택한 조건 확인 및 검색 조건은 직접 수정 가능 합니다.)
              <v-tooltip
                top
                v-model="showTooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="showTooltip=!showTooltip"
                  >
                    <v-icon color="grey lighten-1">
                      mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span class="tooltipTitle">-검색 조건은 '분석대상 그룹선택' 과 '분석대상 검색 조건 선택'을 선택해서 사용가능-</span><br>
                <span class="tooltipTitle">* 알레르기와 과체중을 모두 포함 하는 경우 검색 조건</span><br>
                <span>&nbsp;&nbsp;tag='%|알레르기|%' AND tag='%|과체중|%'</span><br>
                <span class="tooltipTitle">* 알레르기 또는 과체중인 경우 검색 조건</span><br>
                <span>&nbsp;&nbsp;tag='%|알레르기|%' OR tag='%|과체중|%'</span><br>
                <span class="tooltipTitle">* 알레르기와 과체중을 모두 포함 하고 있거나 또는 아토피가 있는 경우 검색 조건</span><br>
                <span>&nbsp;&nbsp;(tag='%|알레르기|%' AND tag='%|과체중|%') OR tag='%|아토피|%'</span><br>
                <span class="tooltipTitle">* 알레르기와 과체중을 모두 포함 하거나 또는 아토피와 과체중을 모두 포함 하는 경우 검색 조건</span><br>
                <span>&nbsp;&nbsp;(tag='%|알레르기|%' AND tag='%|과체중|%') OR (tag='%|아토피|%' AND tag='%|과체중|%')</span>
              </v-tooltip>
              <span v-if="isSampleControlSelection">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                        label="* 샘플군 검색 조건"
                        hide-details="auto"
                        :value="searchCondition"
                        @blur="selectGroupBlur"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                        label="* 대조군 검색 조건"
                        hide-details="auto"
                        :value="searchConditionForControlGroup"
                        @blur="selectGroupBlurForControlGroup"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </span>
              <snan v-else>
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                        :name="constants.CONDITION.G1"
                        label="* 그룹1 검색 조건"
                        hide-details="auto"
                        :value="searchConditionG1"
                        @blur="selectGroupBlurForControlGroup"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                        :name="constants.CONDITION.G2"
                        label="* 그룹2 검색 조건"
                        hide-details="auto"
                        :value="searchConditionG2"
                        @blur="selectGroupBlurForControlGroup"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field
                        :name="constants.CONDITION.G3"
                        label="* 그룹3 검색 조건"
                        hide-details="auto"
                        :value="searchConditionG3"
                        @blur="selectGroupBlurForControlGroup"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </snan>
<!--
              <v-row>
                <v-col cols="8">
                  2. 연령: {{startAgeValue}} ~ {{endAgeValue}}
                </v-col>
              </v-row>
-->
<!--              1. 샘플군 ({{selectedSamples.toString()}} ) vs 대조군 ({{ (isAutoSelect)?"샘플군 외 모든 대상":selectedControlGroup.toString()}}) <br>
              2. 연령: {{startAgeValue}} ~ {{endAgeValue}} -->
            </v-col>
            <v-col cols="1">
            </v-col>
          </v-row>
          </v-card>
        </v-flex>

        <v-flex v-if="selectedAnalysisStr === constants.SELECT_METHOD.ANOVA_TWO || selectedAnalysisStr === constants.SELECT_METHOD.ANOVA_THREE" >
          <v-flex xs12 sm12 md12 >
            <v-row style="padding-top: 0px;">
              <v-col cols="1">
              </v-col>
              <v-col >
                <v-tabs color="#607D8B" v-model="table" >
<!--
                  <v-tabs-slider></v-tabs-slider>
-->
                  <v-tab href="#phylum">Phylum</v-tab>
                  <v-tab href="#genus">Phylum-Genus</v-tab>
                  <v-tab href="#species">Genus-Species</v-tab>
                </v-tabs>
              </v-col>
              <v-col align-self="center" align="right" cols="3" style="	font-size: 13px;">
                <span v-if="selectedAnalysisStr === constants.SELECT_METHOD.ANOVA_THREE">
                샘플수(그룹1: {{searchResult.sampleCodeCnt || 0}},
                그룹2: {{searchResult.controlGroupCnt|| 0}}, 그룹3: {{searchResult.g3Cnt || 0}})
                </span>
                <span v-else>
                샘플수(샘플군: {{searchResult.sampleCodeCnt || 0}},
                대조군: {{searchResult.controlGroupCnt|| 0}})
                </span>
                <v-btn
                    icon
                    color="green"
                    :disabled="isDisableDownloadExcel"
                    @click="downloadExcel"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
<!--
                <v-btn
                    icon
                    color="green"
                    :disabled="isDisableLog"
                    @click="changeResultValue"
                >
                  <v-icon>mdi-math-log</v-icon>
                </v-btn>
-->
<!--                <v-btn
                    color="blue-grey"
                    class="ma-2 green&#45;&#45;text"
                    @click="downloadExcel"
                >

                </v-btn>     -->           <!-- test 버튼 자리 -->
<!--
                <v-btn
                    class="mt-6"
                    text
                    color="error"
                    @click="changeValue"
                >
                  TBD
                </v-btn>
-->

              </v-col>
              <v-col cols="1">
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="1">
              </v-col>
              <!-- TODO 테이블 Template 화 필요 -->
              <v-col justify="center" align="center" >
                <v-tabs-items v-model="table">
                  <v-tab-item
                      :value="'phylum'"
                  >
                    <v-card flat>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-data-table
                          :headers="headers"
                          :items="searchResult.phylum"
                          item-key="name"
                          hide-default-header
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          :loading = "loading"
                          :search="searchText"
                          loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-text-field
                              v-model="searchText"
                              label="Search Name"
                              class="mx-4"
                          ></v-text-field>
                        </template>
                        <template #header="{ }">
                          <thead class="v-data-table-header">
                          <tr>
                            <th v-for="(h,i) in filteredHeaders " :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">

                              <div class="hover" v-if="(h.text === '유의차여부(O/X)')" @click="signSort">
                                {{ h.text }}
                              </div>
                              <div v-else-if="(h.text === '샘플군') || (h.text === '대조군') || (h.text === '그룹1') || (h.text === '그룹2') || (h.text === '그룹3') "  >
                                {{ h.text }}
                              </div>
                              <div class="hover" v-else :id="h.text" @click="sort">
                                {{ h.text }}
                              </div>

                            </th>
                          </tr>
                          <tr>
                            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="height: 35px">
                              <div class="hover" :id="h1.key" @click="sort" >
                                {{ h1.text }}
                              </div>
                            </th>
                          </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td v-for="(c,ci) in getRows(props.item)" :key="ci" class="text-center">
                              <span :class="props.item.significantDifference === 'O' ? 'tdSpan' : '' ">{{ c }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                      :value="'genus'"
                  >
                    <v-card flat>
                      <v-data-table
                          :headers="headers"
                          :items="searchResult.genus"
                          item-key="name"
                          hide-default-header
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          :loading = "loading"
                          :search="searchText"
                          loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-text-field
                              v-model="searchText"
                              label="Search Name"
                              class="mx-4"
                          ></v-text-field>
                        </template>
                        <template #header="{ }">
                          <thead class="v-data-table-header">
                          <tr>
                            <th v-for="(h,i) in filteredHeaders " :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">

                              <div class="hover" v-if="(h.text === '유의차여부(O/X)')" @click="signSort">
                                {{ h.text }}
                              </div>
                              <div v-else-if="(h.text === '샘플군') || (h.text === '대조군') || (h.text === '그룹1') || (h.text === '그룹2') || (h.text === '그룹3') "  >
                                {{ h.text }}
                              </div>
                              <div class="hover" v-else :id="h.text" @click="sort">
                                {{ h.text }}
                              </div>

                            </th>
                          </tr>
                          <tr>
                            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="height: 35px">
                              <div class="hover" :id="h1.key" @click="sort" >
                                {{ h1.text }}
                              </div>
                            </th>
                          </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td v-for="(c,ci) in getRows(props.item)" :key="ci" class="text-center">
                              <span :class="props.item.significantDifference === 'O' ? 'tdSpan' : '' ">{{ c }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                      :value="'species'"
                  >
                    <v-card flat>
                      <v-data-table
                          :headers="headers"
                          :items="searchResult.species"
                          item-key="name"
                          hide-default-header
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          :loading = "loading"
                          :search="searchText"
                          loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-text-field
                              v-model="searchText"
                              label="Search Name"
                              class="mx-4"
                          ></v-text-field>
                        </template>
                        <template #header="{ }">
                          <thead class="v-data-table-header">
                          <tr>
                            <th v-for="(h,i) in filteredHeaders " :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">

                              <div class="hover" v-if="(h.text === '유의차여부(O/X)')" @click="signSort">
                                {{ h.text }}
                              </div>
                              <div v-else-if="(h.text === '샘플군') || (h.text === '대조군') || (h.text === '그룹1') || (h.text === '그룹2') || (h.text === '그룹3') "  >
                                {{ h.text }}
                              </div>
                              <div class="hover" v-else :id="h.text" @click="sort">
                                {{ h.text }}
                              </div>

                            </th>
                          </tr>
                          <tr>
                            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="height: 35px">
                              <div class="hover" :id="h1.key" @click="sort" >
                                {{ h1.text }}
                              </div>
                            </th>
                          </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td v-for="(c,ci) in getRows(props.item)" :key="ci" class="text-center">
                              <span :class="props.item.significantDifference === 'O' ? 'tdSpan' : '' ">{{ c }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col cols="1">
              </v-col>
            </v-row>

          </v-flex>

        </v-flex>

        <v-flex v-else-if="selectedAnalysisStr === 'AiB Chart'">
          <v-flex xs12 sm12 md12 class="pb-3">
            <v-row ref="c1" >
              <v-col justify="start" align="center">
                <div style="height: 200px"
                ></div>
                AiB Chart
              </v-col>
            </v-row>
          </v-flex>
        </v-flex>

        <v-flex v-else-if="selectedAnalysisStr === 'Diversity'">
          <v-flex xs12 sm12 md12 class="pb-3">
            <v-row ref="c1" >
              <v-col justify="start" align="center">
                <div style="height: 200px"
                ></div>
                Diversity
              </v-col>
            </v-row>
          </v-flex>
        </v-flex>

        <v-flex v-else>
          <v-flex xs12 sm12 md12 class="pb-3">
            <v-row ref="c1" >
              <v-col justify="start" align="center" >
                <div style="height: 200px"
                  ></div>
                분석 방법 선택에 따라 변경 되는 영역
              </v-col>
            </v-row>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-container>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import Constants from '../../store/constants'
import XLSX from 'xlsx'

export default {
  name: 'HashTagAnalysis',
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'center',
          sortable: false,
          value: 'microbeName',
        },
        {
          text: '샘플군',
          align: 'center',
          value: 'sampleSum',
          divider: true,
          children: [{ text: "AVG", key: 'sampleAvg'}, { text: "STD", key: "sampleStd"}]
        },
        {
          text: '대조군',
          align: 'center',
          value: 'controlGroupSum',
          children: [{ text: "AVG", key: 'controlGroupAvg' }, { text: "STD", key: "controlGroupStd" }]
        },
        {
          text: '군집차',
          align: 'center',
          value: 'dev',
        },
        {
          text: 'P-value',
          align: 'center',
          value: 'pValue'
        },
        {
          text: 'temp',
          align: 'center',
          value: 'pValue'
        },
        {
          text: 'temp',
          align: 'center',
          value: 'pValue'
        },
        {
          text: '유의차여부(O/X)',
          sortable: true,
          align: 'center',
          value: 'significantDifference'
        },
      ],
      constants : Constants,
      isSampleControlSelection: true, // 2개 비교 버튼
      chooseExceptionStr : "샘플군 외 모든 대상(대조군)",
      showTooltip : false,
      // 샘플군/대조군 선택
      switchGunChar : "S",
      searchCondition : "",
      searchConditionForControlGroup : "",
      searchConditionG1 : "",
      searchConditionG2 : "",
      searchConditionG3 : "",
      isSelectedButton : true,
      isSelectedButtonG1 : true,
      isSelectedButtonG2 : false,
      isSelectedButtonG3 : false,
      searchText : "",
      changeValue : "LOG",
      isDisableDownloadExcel: true,
      isDisableLog: true,
      isSignSortASC : false,
      isPvalueSortASC : false,
      table : null,
      loading : false,
      isLogValue : true,
      isLogValueStr: "로그로 보기",
      isAutoSelect : false,
      calories: '',
      selectedGroup: [],
      selectedSymbol: '',
      itemsSymbol : [
        "(",
          "AND",
          "OR",
          ")"
      ],
      analysisSelectStr : Constants.SELECT_METHOD.ANOVA_TWO,
      analysisSelectList : [
        Constants.SELECT_METHOD.ANOVA_TWO, //"AiB Chart", "Diversity"
        Constants.SELECT_METHOD.ANOVA_THREE,
      ],
      analysisDetailSelectList : [
      ],
      items: [
        '당뇨', '고혈압', '장질환', '알레르기',
        '영아-자연분만', '영아-제왕절개', '치과치료', '항생제처방',
        '샘플군외 모든 대상(대조군)'
      ],
      searchResult: {
        phylum : [],
        genus : [],
        species: []
      },
      isDetailSelect : false,
      selectedSamples : [], //선택된 샘플군
      selectedControlGroup : [], //선택된 대조군
      startAgeValue: 0,
      endAgeValue: 99,
      selectedAnalysisStr : Constants.SELECT_METHOD.ANOVA_TWO

    }

  },
  methods: {
    ...mapActions(['getHashTagList', 'hashSearch']),
    sort (event) {
      if (this.searchResult.phylum.length <= 0 && this.searchResult.genus.length <= 0
          && this.searchResult.species.length <= 0) return;

      let targetAttr = event.target.name || event.target.id;
      let targetName = "";
      let isASC;

      switch (targetAttr) {
        case "P-value" :
          targetName = "pValue";
          break;
        case "군집차" :
          targetName = "dev";
          break;
        case "Name" :
          targetName = "microbeName";
          break;
        case "sampleAvg" :
        case "sampleStd" :
        case "controlGroupAvg" :
        case "controlGroupStd" :
        case "g3Avg" :
        case "g3Std" :
          targetName = targetAttr;
          break;
        default :

      }

      isASC = this.isPvalueSortASC;
      this.isPvalueSortASC = !this.isPvalueSortASC;

      this.searchResult.phylum.sort(sortFunc);
      this.searchResult.genus.sort(sortFunc);
      this.searchResult.species.sort(sortFunc);

      function sortFunc(a, b) {
        if(targetName === "microbeName") {
          return (isASC) ? (a[targetName] < b[targetName] ? -1 : a[targetName] > b[targetName] ? 1 : 0) :
              (a[targetName] > b[targetName] ? -1 : a[targetName] < b[targetName] ? 1 : 0);
        }

        return (isASC) ? a[targetName] - b[targetName] : b[targetName] - a[targetName];
      }

    },
    /**
     * 서버 사이드에서 처리 하려 했으나 인스턴스 용량과 다운로드 시 같은 조건으로 다시 쿼리 그리고 엑셀 데이터 생성 부분을
     * 고려 하여  Search 시 한번에 처리 될 수 있도록 rawData도 같이 내려 주어 클라이언트 사이드에서 처리 되도록 설계 개발 됨.
     * @param searchResult
     */
    excelExport (fileName, resultList) {
      try {
        let byMicrobeMap = {
          phylum : new Map(),
          genus : new Map(),
          species : new Map()
        }

        resultList.reduce( (acc, obj) => {
          let microbeMap = byMicrobeMap[obj.type];
          let getData = microbeMap.get(obj.microbe);
          if (getData) {
            getData[obj.code] = obj.sum;
          } else {
            getData = {
              microbe : obj.microbe,
              [obj.code] : obj.sum,
            };
          }
          microbeMap.set(obj.microbe, getData);
        }, "");

        //console.dir(byMicrobeMap);

        let phylumWS = XLSX.utils.json_to_sheet(Array.from(byMicrobeMap.phylum.values()));
        let genusWS = XLSX.utils.json_to_sheet(Array.from(byMicrobeMap.genus.values()));
        let speciesWS = XLSX.utils.json_to_sheet(Array.from(byMicrobeMap.species.values()));

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, phylumWS, 'phylum');
        XLSX.utils.book_append_sheet(wb, genusWS, 'genus');
        XLSX.utils.book_append_sheet(wb, speciesWS, 'species');

        XLSX.writeFile(wb, fileName);

      } catch (e){
        console.error(e);
        alert("엑셀 다운로드 실패");
      }
    },
    signSort : function() {
      if (this.searchResult.phylum.length <= 0 && this.searchResult.genus.length <= 0
          && this.searchResult.species.length <= 0) return;

      this.isSignSortASC = !this.isSignSortASC;

      sort(this.searchResult.phylum, this.isSignSortASC).then(obj => this.searchResult.phylum = obj);
      sort(this.searchResult.genus, this.isSignSortASC).then(obj => this.searchResult.genus = obj);
      sort(this.searchResult.species, this.isSignSortASC).then(obj => this.searchResult.species = obj);

      async function sort(dataList, sortBool){
        let o = [];
        let x = [];
        dataList.reduce( (acc, obj) => {
          if(obj.significantDifference === "O"){
            o.push(obj);
          }else {
            x.push(obj);
          }
        }, []);
        dataList = (sortBool)?[...o, ...x] : [...x, ...o];
        o = null;
        x = null;

        return dataList;
      }
    },
    //군집 분석 실행
    run : function() {
      this.loading = !this.loading;
      this.search().then( ret => {
        if(!ret) {
          this.loading = false;
          return;
        }
        //setTimeout( ()=> { this.loading = !this.loading }, 3000);
      }).catch( e=> {
        console.log(e);
        this.loading = false;
      })

    },
    // 조건 결과 값 변경 (재 연산 필요로 현재 조건으로 요청)
    changeResultValue : function(isLogValue) {
      this.isLogValue = isLogValue;
      this.isLogValueStr = (this.isLogValue)?"로그로 보기":"농도(%)로 보기";

    },
    getSubHeader(headers) {
      let result = [];
      headers.
          filter(i => i.children).
          forEach(v => {
            result = result.concat(v.children);
          });
      return result;
    },
    getRows(rows) {

      //row 에서 순서대로 출력하기 때문에 순서 주의
      let rowDataObj = {
        microbeName : "",
        sampleAvg : 0,
        sampleStd : 0,
        controlGroupAvg : 0,
        controlGroupStd : 0,
        dev : 0,
        pValue : 0,
        significantDifference : "X"
      };

      if(this.selectedAnalysisStr === Constants.SELECT_METHOD.ANOVA_THREE) {
        rowDataObj = {
          microbeName : "",
          sampleAvg : 0,
          sampleStd : 0,
          controlGroupAvg : 0,
          controlGroupStd : 0,
          g3Avg : 0,
          g3Std : 0,
          pValue : 0,
          significantDifference : "X"
        };
      }

      Object.keys(rows).reduce( (acc, key) => {
        if(rowDataObj[key] !== undefined){
          rowDataObj[key] = rows[key];
        }
        return acc;
      }, {});

      return rowDataObj;
    },
    checkSelectedGroup : function() {
      if (this.selectedGroup.length <= 0) {
        alert("1개 이상의 분석대상 그룹선택 먼저 선택 해주세요.");
        return false;
      }
      return true;
    },
    //분석 대상 그룹 선택시 호출(태그)
    selectGroup : function() {
      //분석 방벙 선택에 따라 분리
      switch (this.selectedAnalysisStr) {
        case Constants.SELECT_METHOD.ANOVA_TWO:
          //샘플군/대조군 선택 버튼
          if (this.isSelectedButton) {
            this.searchCondition += `tag = '%|${this.selectedGroup}|%'`;
          } else { //대조군 일 경우 예외 처리
            if(this.isCheckSelectException()){
              this.selectedGroup = {};
              return;
            }
            // 대조군일때 예외 선택일 경우 기존 선택 조건 모두 삭제 후 저장
            if(this.selectedGroup.includes(this.chooseExceptionStr)) {
              this.searchConditionForControlGroup =`tag = '%|${this.selectedGroup}|%'`;
            }else {
              this.searchConditionForControlGroup += `tag = '%|${this.selectedGroup}|%'`;
            }
          }
          break;
          // 3개 분석
        case Constants.SELECT_METHOD.ANOVA_THREE:
          // 어떤 버튼을 눌렀는지에 따라 분기
console.log("in", this.switchGunChar, Constants.CONDITION.G1)
          if(this.switchGunChar === Constants.CONDITION.G1)
            this.searchConditionG1 +=`tag = '%|${this.selectedGroup}|%'`;

          if(this.switchGunChar === Constants.CONDITION.G2)
            this.searchConditionG2 +=`tag = '%|${this.selectedGroup}|%'`;

          if(this.switchGunChar === Constants.CONDITION.G3)
            this.searchConditionG3 +=`tag = '%|${this.selectedGroup}|%'`;

          break;
        default :
          this.selectedGroup = {};
      }

      this.selectedGroup = {};


    },
    //분석 대상 조건 선택 시 호출
    selectCondition : function() {

      switch (this.selectedAnalysisStr) {
        case Constants.SELECT_METHOD.ANOVA_TWO:
          if (this.isSelectedButton) {
            this.searchCondition += ` ${this.selectedSymbol} `;
          }else { // 대조군 일때
            if(this.isCheckSelectException()){
              this.selectedSymbol = {};
              return;
            }
            this.searchConditionForControlGroup += ` ${this.selectedSymbol} `;
          }
          break;

        case Constants.SELECT_METHOD.ANOVA_THREE:
          if(this.switchGunChar === Constants.CONDITION.G1){
            this.searchConditionG1 +=` ${this.selectedSymbol} `;
            break;
          }

          if(this.switchGunChar === Constants.CONDITION.G2) {
            this.searchConditionG2 +=` ${this.selectedSymbol} `;
            break;
          }

          if(this.switchGunChar === Constants.CONDITION.G3) {
            this.searchConditionG3 +=` ${this.selectedSymbol} `;
            break;
          }
      }

      this.selectedSymbol = {};
    },
    selectGroupBlur : function(e) {
      console.dir(e)
      this.searchCondition = e.target._value;
    },
    //대조군 일때 예외 선택이 되었는지 확인
    isCheckSelectException : function() {
      if(this.searchConditionForControlGroup.includes(this.chooseExceptionStr)){
        alert(` "${this.chooseExceptionStr}" 선택 상태에서는 추가 선택 또는 수정 할 수 없습니다.`);
        this.searchConditionForControlGroup = this.searchConditionForControlGroup + ' ';
        return true;
      }
      return false;
    },
    // 검색 조건을 직접 수정 했을 경우 다시 변수에 저장
    selectGroupBlurForControlGroup : function(e) {
      switch (e.target.name) {
        case "G1" :
          this.searchConditionG1 = e.target._value;
          break;
        case "G2" :
          this.searchConditionG2 = e.target._value;
          break;
        case "G3" :
          this.searchConditionG3 = e.target._value;
          break;
        default :
          if(e.target._value.length > 0){
            if(this.isCheckSelectException()) return;
          }
          this.searchConditionForControlGroup = e.target._value;
          break;
      }

    },
    samples : function(e){
      this.isSelectedButton = true;
      this.setSwitchGunChar(e);
      this.deleteLastElementTagList();
      console.log(this.switchGunChar);
      return;
    },
    deleteLastElementTagList : function () {
      let lastItem = this.items[this.items.length-1];
      if(this.chooseExceptionStr === lastItem) this.items.pop();
    },
    compareThreeGroupButton : function(e) {
      this.setSwitchGunChar(e);
      console.log(this.switchGunChar);
      this.compareThreeSelection();
    },
    // 3개 분석시 버튼 활성화 / 비 활성화 처리
    compareThreeSelection : function() {
      this.allSelectButtonGFalse();
      switch (this.switchGunChar) {
        case 'G1' :
          this.isSelectedButtonG1 = true;
          break;
        case 'G2' :
          this.isSelectedButtonG2 = true;
          break;
        case 'G3' :
          this.isSelectedButtonG3 = true;
          break;
      }

    },
    allSelectButtonGFalse : function() {
      this.isSelectedButtonG1 = false;
      this.isSelectedButtonG2 = false;
      this.isSelectedButtonG3 = false;
    },
    setSwitchGunChar : function(e){
      this.switchGunChar = (e.target.value)?e.target.value:e.target.parentNode.value;
    },
    controlGroup : function(e){
      this.isSelectedButton = false;
      this.setSwitchGunChar(e);
      this.items.push(this.chooseExceptionStr);
      console.log(this.switchGunChar);
      return;
    },
    // 분석 방법 선택
    changeAnalysisSelect: function(selectedStr)  {
      this.selectedAnalysisStr = selectedStr;
      switch (selectedStr){
        case Constants.SELECT_METHOD.ANOVA_TWO:
          this.isDetailSelect = false;
          this.isSampleControlSelection = true;
          //대조군 일때 "샘플군 외 모든 대상" 추가
          if(this.switchGunChar === 'G') this.items.push(this.chooseExceptionStr);
          this.changeAnovaTwoHeaders();
          break;
        case Constants.SELECT_METHOD.ANOVA_THREE:
          this.switchGunChar = Constants.CONDITION.G1;
          this.compareThreeSelection();
          this.isDetailSelect = false;
          this.isSampleControlSelection = false;
          this.deleteLastElementTagList();
          this.changeAnovaThreeHeaders();
          break;
        case "AiB Chart" :
          this.isDetailSelect = true;
          this.analysisDetailSelectList = ["보편적 분류(General)", "Genus(Top50)", "Species(Top100)"]
          break;
        case "Diversity" :
          this.isDetailSelect = true;
          this.analysisDetailSelectList = ["나이별", "Phylum", "Genus(Top50)"]
          break;
        default :
            this.isDetailSelect = true;
      }
    },
    changeAnovaThreeHeaders : function() {
      this.headers[1].text = "그룹1";
      this.headers[2].text = "그룹2";
      this.headers.splice(3, 0,{
        text: '그룹3',
        align: 'center',
        value: 'g3Sum',
        children: [{ text: "AVG", key: 'g3Avg' }, { text: "STD", key: "g3Std" }]
      },)
      this.headers.splice(7, 0,   {
        text: 'temp',
        align: 'center',
        value: 'pValue'
      },)
      //군집차 제거
      this.headers.splice(4, 1);
      this.searchResult = {};
    },
    changeAnovaTwoHeaders : function() {
      this.headers[1].text = "샘플군";
      this.headers[2].text = "대조군";
      //군집차 포함
      this.headers.splice(4, 0, {
        text: '군집차',
        align: 'center',
        value: 'dev'
      })
      this.headers.splice(3, 1);
      this.headers.splice(7, 1);
      this.searchResult = {};
    },
    maxAgeLengthCheck : function(e) {
      if(e.target.value) {let value = parseInt(e.target.value) || -1;if (value > 100) {e.target.value = 100;
          }else if (e.target.value < 0) {
            e.target.value = "";
          }
        }
    },
    startAge : function(e) {
      this.maxAgeLengthCheck(e);
      this.startAgeValue = e.target.value;
    },
    endAge : function(e) {
      this.maxAgeLengthCheck(e);
      this.endAgeValue = e.target.value;
    },
    loadingToggle : function() {
      this.loading = !this.loading;
    },
    replaceCondition: function(str) {
      return str.replace(/=/g," like ");
    },
    validSearch : function() {

      //분석 방법 선택에 따라
      switch (this.selectedAnalysisStr) {
        case Constants.SELECT_METHOD.ANOVA_TWO:
          if( this.searchCondition.length <= 10){
            alert("선택된 샘플군이 없습니다.");
            return false;
          }

          if( this.searchConditionForControlGroup.length <= 10){
            alert("선택된 대조군이 없습니다.");
            return false;
          }
          break;
        case Constants.SELECT_METHOD.ANOVA_THREE:
          if( this.searchConditionG1.length <= 10 ) {
            alert("선택된 그룹1 조건이 없습니다.");
            return false;
          }
          if( this.searchConditionG2.length <= 10 ) {
            alert("선택된 그룹2 조건이 없습니다.");
            return false;
          }
          if( this.searchConditionG3.length <= 10 ) {
            alert("선택된 그룹3 조건이 없습니다.");
            return false;
          }
          break;
      }

      return true;
    },
    search : async function(){

      if(!this.validSearch()) return;

      let searchData = {
        selectedStr : 'anova',  // 현재는 고정
        selectedSamples : this.selectedSamples, //멀티 선택 (OR) 처리시, 2022-02-05 에서 사용하지 않음.
        selectedControlGroup : this.selectedControlGroup, // 상동
        searchMethod : Constants.SEARCH_METHOD_TYPE.ANOVA_TWO,
        searchConditionSample : this.replaceCondition(this.searchCondition),
        searchConditionControlGroup : this.replaceCondition(this.searchConditionForControlGroup),
        searchConditionG3 : "",
        isLogValue : this.isLogValue,
        age : {
          from : this.startAgeValue,
          to : this.endAgeValue
        }
      }
      //Anova 분석 3개 일 경우, 기존 파라미터 sample = G1, controlGroup = G2 로 재활용.
      if(this.selectedAnalysisStr === Constants.SELECT_METHOD.ANOVA_THREE) {
        searchData.searchMethod = Constants.SEARCH_METHOD_TYPE.ANOVA_THREE;
        searchData.searchConditionSample = this.replaceCondition(this.searchConditionG1);
        searchData.searchConditionControlGroup = this.replaceCondition(this.searchConditionG2);
        searchData.searchConditionG3 = this.replaceCondition(this.searchConditionG3);
      }

      let searchResult = await this.hashSearch(searchData);

      //.this.changeSearchValue(searchResult, false);
      this.searchResult = searchResult;
      if (this.searchResult.phylum.length === 0){
        alert("데이터 없음");
        this.isDisableDownloadExcel = true;
      } else {
        this.isDisableDownloadExcel = false;
      }

      this.loading = false;

      //For Test
      //this.excelExport("샘플군.xlsx", searchResult.rawSampleList);
      //this.excelExport("대조군.xlsx", searchResult.rawControlGroupList);

      return true;
    },
    downloadExcel(){
      if(this.searchResult.rawSampleList.length > 0) {
        let g1Filename = "샘플군.xlsx";
        let g2F1ilename = "샘플군.xlsx";
        let g3F1ilename = "그룹3.xlsx";
        if (this.selectedAnalysisStr === Constants.SELECT_METHOD.ANOVA_THREE) {
          g1Filename = "그룹1.xlsx";
          g2F1ilename = "그룹2.xlsx";
          this.excelExport(g3F1ilename, this.searchResult.rawG3List);
        }
        this.excelExport(g1Filename, this.searchResult.rawSampleList);
        this.excelExport(g2F1ilename, this.searchResult.rawControlGroupList);

        alert("다운로드가 완료 되었습니다.");
      }else {
        alert("데이터가 없습니다.");
      }
    },
    changeValue(){
      this.changeLogValue = !this.changeLogValue;
      this.changeSearchValue(this.searchResult, this.changeLogValue);
    },
    changeSearchValue (searchResult = {}, changeLogValue = false) {
      Object.keys(searchResult).reduce( (acc, level) => {
        if (!Array.isArray(searchResult[level])) return acc;
        searchResult[level].reduce( (levelAcc, obj) => {
          if(changeLogValue) {
            obj.sampleSum = Math.log10(obj.sampleSum).toFixed(2);
            obj.controlGroupSum = Math.log10(obj.controlGroupSum).toFixed(2);
          } else {
            obj.sampleSum = obj.sampleSum.toExponential(1);
            obj.controlGroupSum = obj.controlGroupSum.toExponential(1);
          }
          obj.pValue = obj.anova.pValue;
          obj.significantDifference = "X";
          if(obj.pValue < 0.05){
            obj.significantDifference = "O";
          }
        }, {});
      }, "");
    }
  },
  mounted() {
    if(this.selectSpeciesAllList.phylumList.length <= 0){
      this.getSelectList();
    }
  },
  created () {
    this.loadingToggle();
    this.getHashTagList().then( (list)=> {
      this.items = list;
      this.loadingToggle();
    })
  },
  computed : {
    // header slot 처리 했기 때문에 ...  slot 에서는 정상적인 row 가 필요, headers 에서는 서브 해더를 모르기 때문에  row 수를 임의로 추가 하여 처리 (해더는 서브 해더 수 만큼 필요함)
    filteredHeaders () {
      return this.headers.filter(item => item.text !== 'temp');
    },

    headerThree () {
      return [
        {
          text: 'Name',
          align: 'center',
          sortable: false,
          value: 'microbeName',
        },
        {
          text: '샘플군',
          align: 'center',
          value: 'sampleSum',
          divider: true,
          children: [{ text: "AVG", key: 'sampleAvg'}, { text: "STD", key: "sampleStd"}]
        },
        {
          text: '대조군',
          align: 'center',
          value: 'controlGroupSum',
          children: [{ text: "AVG", key: 'controlGroupAvg' }, { text: "STD", key: "controlGroupStd" }]
        },
        {
          text: '군집차',
          align: 'center',
          value: 'dev',
        },
        {
          text: 'P-value',
          align: 'center',
          value: 'pValue'
        },
        {
          text: 'temp',
          align: 'center',
          value: 'pValue'
        },
        {
          text: 'temp',
          align: 'center',
          value: 'pValue'
        },
        {
          text: '유의차여부(O/X)',
          sortable: true,
          align: 'center',
          value: 'significantDifference'
        },
      ]
    },
    selectSpeciesAllList() {
      return this.$store.state.selectList;
    },
    codeList() {
      return this.$store.state.codeList;
    }
  }

}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}
.vCard {
 border: 1px solid white;
}
.hover {
  cursor : pointer !important;
}
.hover:hover {
  color: #008EDC;
}
.searchConditionAreaType1 {
  border-bottom: 3px double #cbcbcb;
  height: 180px;
  font-size: 13px;
}
.searchConditionAreaType2 {
  border-bottom: 3px double #cbcbcb;
  height: 220px;
  font-size: 13px;
}
.buttonFocusIn {
  background-color: #5885AF !important;
  color: #ffffff;
  cursor: pointer;
  outline: none;
}
.buttonFocusOut {
  background-color: #f1f0f0 !important;
  cursor: pointer;
  outline: none;
}
.tdSpan { color : #d52136
}
.anovaTable{
  font-size: 13px;
  border-style: hidden;
  border-collapse: collapse;
}
.anovaTd{
  padding: 5.5px;
  margin: 0;
  width:10px;border-style: hidden;
  text-align: left;
  vertical-align: middle;
}
.inputAge{
  width:45px;height:35px;border: solid 1px;text-align:center;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.spanTitle{
  font-size: 13px !important;
  color: #747883;
}
/*.v-data-table::v-deep td {
  font-size: 12px !important;
}*/
.v-text-field >>> label {
  font-size: 13px;
}
.tooltipTitle { color: yellow }
</style>

